import { Controller } from "@hotwired/stimulus";
import * as colorStorage from "../storages/color_storage";

export default class extends Controller {
    static targets = [ "map", "logo" ];

    connect() {
        this.update();
    }

    update() {
        const color = colorStorage.get();

        this.mapTarget.classList.add(color);

        this.logoTarget
            .setAttribute('src', this.logoTarget.dataset[color])
        ;
    }
}