
/**
 * @typedef {Object} PopupOption
 * @property {string} title - The title of the popup.
 * @property {string} message - The message displayed in the popup.
 * @property {string} textButton - The text of the button to close the popup.
 * @property {string} bgColor - HEX ONLY ! The background color class for the popup.
 * @property {string} textColor - HEX ONLY ! The text color class for the popup.
 */

/**
 * @type {{ success: PopupOption, error: PopupOption }}
 */
const options = {
    success: {
        title: "Succès",
        message: "Votre formulaire a bien été envoyé !",
        textButton: "Fermer",
        bgColor: "#1AC59F",
        textColor: "#818B95"
    },
    error: {
        title: "Erreur",
        message: "Un problème a été rencontré lors de la soumission de votre formulaire.",
        textButton: "Fermer",
        bgColor: "#FB5151",
        textColor: "#818B95"
    }
}

const errorSvg = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="w-full h-full"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" /></svg>`
const successSvg = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="w-full h-full"><path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>`

export const createPopup = ({ success, error } = options) => {
    const params = new URLSearchParams(window.location.search);

    if (!params.get("yama-ftn")) {
        return;
    }

    const isSucces = params.get("yama-ftn") === "success";
    const currentStatus = isSucces ? success : error;

    const container = document.createElement("div");
    container.innerHTML = `<div class="fixed inset-0 w-full h-full bg-black bg-opacity-50 z-50 duration-300">
        <div class="absolute transform top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-50 w-72 bg-white rounded-md md:w-96">
            <div style="background-color: ${currentStatus.bgColor}" class="w-16 h-16 absolute transform -top-8 left-1/2 -translate-x-1/2 rounded-full p-2">
                ${isSucces ? successSvg : errorSvg}
            </div>
            <div class="p-4">
                <span style="color: ${currentStatus.textColor}" class="text-[1.5rem] font-sans font-black mt-6 tracking-wide inline-block w-full h-fit text-center">${currentStatus.title}</span>
                <p style="color: ${currentStatus.textColor}" class="text-[1rem] font-sans font-normal mt-4 tracking-wide inline-block w-full h-fit text-center opacity-75">${currentStatus.message}</p>
            </div>
            <div class="w-full h-fit flex justify-center p-3 rounded-b-md bg-[#F1F5F8]">
                <button style="background-color: ${currentStatus.bgColor}" class="relative p-2 px-4 cursor-pointer rounded-md text-white w-full hover:brightness-110 duration-150">${currentStatus.textButton}</button>
            </div>
        </div>
    </div>`;

    document.body.appendChild(container);
    listenForClose(container);
}

const listenForClose = (el) => {
    el.querySelector("button").addEventListener("click", () => {
        el.querySelector("div").classList.add("opacity-0");
        setTimeout(() => el.remove(), 300);
    });
}