import { Controller } from "@hotwired/stimulus";
import * as colorStorage from "../storages/color_storage";

export default class extends Controller {
    static targets = [ "home" ];

    connect() {
        this.update();
    }

    update() {
        const color = colorStorage.get();

        this.homeTarget
            .setAttribute('href', this.homeTarget.dataset[color])
        ;
    }
}