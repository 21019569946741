import './app/kernel';
import * as glide from "./module/glide";
import { createPopup } from './module/ftnPopup';

function mountGlide(selector, options)
{
    if(document.querySelector(selector)) {
        glide.create(selector, options).mount();
    }
}

mountGlide('.glide-catalog', {
    type: 'carousel',
    perView: 4,
    breakpoints: {
        1280: {
            perView: 3
        },
        1024: {
            perView: 2
        }
    }
});

mountGlide('.glide-review', {
    type: 'slider'
});

createPopup();