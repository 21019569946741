import '../../css/tailwind.css';
import '../../css/main.css';

import { Application } from "@hotwired/stimulus";
import HeaderController from "./controllers/header_controller";
import FooterController from "./controllers/footer_controller";
import BreadcrumbController from "./controllers/breadcrumb_controller";

window.Stimulus = Application.start();

Stimulus.register("header", HeaderController);
Stimulus.register("footer", FooterController);
Stimulus.register("breadcrumb", BreadcrumbController);