import { Controller } from "@hotwired/stimulus";
import * as colorStorage from "../storages/color_storage";

export default class extends Controller {
    static targets = [ "home", "menu", "logo" ];

    static values = {
        color: String
    }

    connect() {
        let color = this.colorValue;

        if(color) {
            colorStorage.set(color);
        }

        this.update();
    }

    update() {
        const color = colorStorage.get();

        document.documentElement.style
            .setProperty('--color-lf', `var(--${color}-lf)`)
        ;

        this.menuTarget.querySelectorAll('ul a').forEach((element) => {
            element.classList.remove('bg-lf', 'text-white');
        });

        this.menuTarget.querySelector(`ul a[data-header-color-param=${color}]`)
            .classList.add('bg-lf', 'text-white')
        ;

        this.menuTarget.querySelector(`nav[data-color=${color}]`)
            .classList.add('md:block')
        ;

        this.homeTarget
            .setAttribute('href', this.homeTarget.dataset[color])
        ;

        this.logoTarget
            .setAttribute('src', this.logoTarget.dataset[color])
        ;
    }

    toggleMenu() {
        const color = colorStorage.get();
        const items = this.menuTarget.querySelectorAll('.menu-item');

        for (const itemElement of items) {
            if(itemElement.dataset.color && itemElement.dataset.color != color) {
                continue;
            }
            itemElement.classList.toggle('hidden');
        }
    }

    switchColor(event) {
        colorStorage.set(event.params.color);
    }
}